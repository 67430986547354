<template>
  <b-modal
    :id="modalId"
    size="md"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @shown="setData"
    @hidden="handleCloseModal"
  >
    <b-form
      class="blog-form"
      @submit="handleSubmit"
    >
      <b-form-group
        label="Title"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          required
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-md="2"
        label-align="right"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          required
        />
      </b-form-group>
      <b-form-group
        label="Tags"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <multiselect
          class="blog-modal-tags"
          :options="tagsList"
          v-model="form.tags"
          tag-placeholder="Add this as new tag"
          placeholder="Search or add a tag"
          label="title"
          track-by="id"
          :multiple="true"
          :taggable="true"
          @tag="addTag"
        >
        </multiselect>
      </b-form-group>
      <b-form-group
        label="Preview"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-file
          v-model="form.preview"
          accept="image/png"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <b-form-group
        label="File"
        label-cols-md="2"
        label-align="right"
        label-for="title"
      >
        <b-form-file
          accept="application/pdf"
          v-model="form.file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          <b-spinner small v-if="form.loading" label="Spinning"></b-spinner>
          {{ buttonText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
// import {validateForm} from '../../../utils/validation';
import {FORM_MODE} from '../../../utils/constants';
import Multiselect from "vue-multiselect";

export default {
  name: 'blogModalForm',
  props: {
    'modalId': {
      type: String,
      required: true
    },
    'initialData': Object,
    formMode: String,
    modalTitle: String
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      form: {
        title: null,
        description: null,
        preview: null,
        file: null,
        tags: [],
        loading: false
      },
    }
  },
  computed: {
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Add' : 'Save';
    },
    tagsList () {
      return this.$store.getters['blogs/blogTagList']
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.form);
      this.clearForm();
    },
    clearForm() {
      this.form.title = null;
      this.form.description = null;
      this.form.preview = null;
      this.form.file = null;
      this.form.tags = [];
    },
    handleCloseModal() {
      this.clearForm()
    },
    async addTag(e) {
      await this.$store.dispatch('blogs/createBlogTag', {
        title: e
      })
      await this.$store.dispatch('blogs/getBlogTags');
    },
    setData() {
      this.form.title = this.initialData ? this.initialData.title : null;
      this.form.description = this.initialData ? this.initialData.description : null;
      // this.form.preview = value ? value.preview : null;
      // this.form.file = value ? value.file : null;
      this.form.tags = this.initialData ? this.initialData.tags : [];
    },
    setLoading(isLoading){
      this.loading = isLoading;
    }
  },
}
</script>

<style lang="scss">
.survey-form {
  .custom-control-label {
    vertical-align: middle;
  }
}
.blog-modal-tags .multiselect__content-wrapper {
  max-height: 150px !important;
}

</style>
